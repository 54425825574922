import {UserActions} from '../../actions/user-actions';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import Config from '../../../config';

@inject(UserActions, EventAggregator, Config)
export class NewPerson{
    constructor(actions, ea, config){
        this.actions = actions;
        this.ea = ea;
        this.config = config;
    }
    newPerson(e){
        e.preventDefault();
        e.stopPropagation();
        let obj = {
            name: this.pname.value,
            id: this.pid.value,
            height: eval(this.pheight.value),
            weight: eval(this.pweight.value),
            gender: this.pismale.checked?'male':'female',
            age: (new Date()).getFullYear() - parseInt(this.pbirthyear.value),
            yearOfBirth: eval(this.pbirthyear.value),
            fatPercentage: eval(this.pfatpercent.value),
            restingHeartRate: eval(this.prestinghr.value),
            maximumHeartRate: eval(this.pmaxhr.value),
            maxOxygenUptake: eval(this.poxygen.value),
            coreTemperatureUpperLimit: eval(this.ctul.value),
            coreTemperatureLowerLimit: eval(this.ctll.value),
            skinTemperatureUpperLimit: eval(this.stul.value),
            skinTemperatureLowerLimit: eval(this.stll.value),
            neutralCoreTemperature: eval(this.ncoretemperature.value),
            wholeBodySweatingrateMax: eval(this.maxwbsr.value),
            wholeBodySweatingrateMin: eval(this.minwbsr.value),
            sweatingSlope: eval(this.sweatingslope.value),
            deltaSweatingOnsetTemperature: eval(this.dsot.value),
            /*bloodVesselAge: eval(this.bloodvesselage.value),*/
            maxSkinBloodFlow: eval(this.maxskinbf.value),
            basalSkinBloodFlow: eval(this.baskinbf.value),
            minSkinBloodFlow: eval(this.minskinbf.value),
            skinMaxCoefficient: eval(this.cfskinmax.value),
            skinMinCoefficient: eval(this.cfskinmin.value)

        };
        console.log(obj);
        this.actions.addPerson(obj).then((res)=>{
            this.ea.publish("persons-nav", {route:'persons-list'})
        });
    }
    closePerson(){
        this.ea.publish("persons-nav", {route:'persons-list'});
    }
}