import {PLATFORM, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

@inject(EventAggregator)
export class Persons{
    constructor(ea){
        this.ea = ea;
    }
    attached(){
        this.navsub = this.ea.subscribe("persons-nav", (opts)=>{
            this.router.navigateToRoute(opts.route);
        });
    }
    detached(){
        this.navsub.dispose();
    }
    configureRouter(config, router){
        this.router = router;
        config.map([
            {route:"/", name:"persons-list", moduleId:PLATFORM.moduleName('./persons-pages/persons-list')},
            {route:"/new-person", name:"new-person", moduleId:PLATFORM.moduleName('./persons-pages/new-person')},
            {route:"/person", name:"person", moduleId: PLATFORM.moduleName('./persons-pages/person-info')}
        ])
    }
}