export default class AppState{
    constructor(){
        this.users = [];
        this.tests = [];
        this.testdata = {};
        this.replay = {
            on: false,
            speed: 1,
        }
        this.lastTime = 0;
        this.init();
    }
    toEnglish(){

    }
    init(){
        let selectedtest = localStorage.getItem("htm-selected-test");
        if(selectedtest){
            this.selectedTest = JSON.parse(selectedtest);
        }

    }
}