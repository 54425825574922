import {bindable} from 'aurelia-framework';

export class YesNo{
    @bindable done;
    @bindable message;
    @bindable show;
    constructor(){

    }
    Yes(){
        if(this.done){
            this.done({yes: true});
        }
    }
    No(){
        if(this.done){
            this.done({yes: false});
        }
    }
}