import {ApiService} from '../../services/api-service';
import {inject} from 'aurelia-framework';
import AppState from '../app-state';

@inject(ApiService, AppState)
export class UserActions{
    constructor(api, state){
        this.api = api;
        this.state = state;
    }
    getPersons(){
        return this.api.getPersons().then((res)=>{
            this.state.persons = res.persons;
            return res;
        });
    }
    addPerson(user){
        return this.api.addPerson(user).then((res)=>{
            this.state.users.push(res);
            return res;
        });
    }

    editPerson(user){
        Object.assign(this.state.selectedPerson, user);
        return this.api.editPerson(user).then((res)=>{
            return res;
        });
    }
    deletePerson(index){
        let user = this.state.persons[index];
        return this.api.removePerson(user).then((res)=>{
            this.state.persons.splice(index, 1);
            return res;
        });
    }
}