import Config from '../config.js';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

@inject(Config, EventAggregator)
export class Home{
    constructor(config, ea){
        this.config = config;
        this.ea = ea;
    }
    attached(){
        this.navsub = this.ea.subscribe("home-nav", (options)=>{
            this.router.navigateToRoute(options.route);
        });
    }
    detached(){
        this.navsub.dispose();
    }
    activate(){
        console.log(this.router);
    }
    nav(route){
        this.router.navigateToRoute(route);
    }
    configureRouter(config, router){
        this.router = router;
        config.map([
            {route:'', redirect:'tests'},
            {route:'/tests', name:'tests', moduleId: PLATFORM.moduleName('./pages/test-cases')},
            {route:'/persons', name:'persons', moduleId: PLATFORM.moduleName('./pages/persons')},
            {route:'/info', name:'info', moduleId: PLATFORM.moduleName('./pages/info')}
        ]);
    }
    logout(){
        this.ea.publish("logout");
    }
}