import {PLATFORM, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {TestActions} from '../../actions/test-actions';
import {UserActions} from '../../actions/user-actions';
import AppState from '../../app-state';
import Config from '../../../config';

@inject(EventAggregator, TestActions, AppState, UserActions, Config)
export class NewTestCase{
    constructor(ea, actions, state, useractions, config){
        this.ea = ea;
        this.state = state;
        this.actions = actions;
        this.config = config;
        this.useractions = useractions;
        this.useractions.getPersons();
        this.test = {
            title:"",
            shortDescription:"",
            description:"",
            variableParameters:{
                temperature: 22,
                humidity: 30,
                clothing:"FIREFIGHTER"
            },
            persons:[]
        };
    }
    addPerson(person){
        let index = this.actions.hasPerson(this.test, person);
        if(index == -1){
            this.test.persons.push(person.id);
        }
    }
    removePerson(person){
        let index = this.actions.hasPerson(this.test, person);
        if(index != -1){
            this.test.persons.splice(index, 1);
        }
    }
    addTest(e){
        e.preventDefault();
        e.stopPropagation();
        let obj = {
            title: this.ttitle.value,
            shortDescription: this.tshort.value,
            description: this.tdesc.value,
            status:"in-active",
            variableParameters:[
                {
                    temperature: parseFloat(this.ttemp.value),
                    humidity: parseFloat(this.thum.value),
                    convectiveHeatCoefficient: parseFloat(this.thc.value),
                    clothing: this.tcloth.value
                }
            ]
        };
        obj.persons = this.test.persons;
        this.state.selectedTest = obj;
        this.actions.addTest(obj).then((res)=>{
            this.actions.openTest(res);
            this.ea.publish("test-case-nav", {route:"running-test"});
        });
    }
    getPersonName(id){
        for(let i = 0; i < this.state.persons.length; i++){
            if(this.state.persons[i].id == id){
                return this.state.persons[i].name;
            }
        }
        return;
    }
    
}