import {inject, bindable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale} from 'chart.js';
import {TestActions} from '../../actions/test-actions';
import AppState from '../../app-state';
import Config from '../../../config';
import {DateTool} from '../../tools/date-tool';


/*

person-card.js:266 Uncaught TypeError: this.arr[this.count].calculations[this.measure].toFixed is not a function
    at person-card.js:26

*/

@inject(EventAggregator, TestActions, AppState, Config, DateTool)
export class PersonCard{
    @bindable person;
    @bindable measure = "coreTemperature"
    @bindable index;
    @bindable showperson;
    @bindable issingle = false;
    constructor(ea, actions, state, config, dt){
        this.ea = ea;
        this.config = config;
        this.arraySize = 10;
        this.dt = dt;
        this.arr = [];
        this.labels = [];
        this.count = 0;
        this.tab = "chart";
        this.reverseArr = [];
        this.actions = actions;
        this.state = state;
        this.unit = "°C";
        this.replaying = false;
        this.limit = 20;
        this.skip = 0;
        this.lastValue = "";
    }
    bind(){

    }
    showPerson(){
        if(this.showperson){
            this.showperson({index: this.index});
        }
    }
    attached(){
        console.log(this.person);
        if(!this.person){
            return;
        }
        Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale);
        this.unit = this.getUnit();
        this.chart = new Chart(this.chartcanvas, {
            type:'line',
            data:{
                labels: this.labels,
                datasets:[{
                    label:"Core Temperature",
                    data: this.arr,
                    borderColor: 'rgb(100, 100, 100)',
                    pointRadius: 4,
                    pointBackgroundColor: "#333"
                }]
            },
            options:{
                responsive: true,
                scales:{
                    y:{
                                suggestedMin: this.config.minmaxs[this.measure].min,
                                suggestedMax: this.config.minmaxs[this.measure].max,
                                stepSize: this.config.minmaxs[this.measure].step
                    }
                    
                }
            }
        });

        if(this.state.selectedTest.status != "active"){
            this.actions.getMeasurements(this.person.id).then((res)=>{
                for(let i = 0; i < res.data.length; i++){
                    this.count++;
                    if(this.measure == "heartrate"){
                        this.chart.config.data.datasets[0].data.push(parseFloat(parseFloat(res.data[i].measurement[this.measure]).toFixed(2)));
                    }else{
                        this.chart.config.data.datasets[0].data.push(parseFloat((parseFloat(res.data[i].calculations[this.measure])).toFixed(2)));
                    }
                    this.chart.config.data.labels.push(this.dt.timeFromSeconds(res.data.timestamp?res.data.timestamp:res.data[i].createdAt));
                    this.reverseArr = this.reverse();
                    this.chart.update();
                }
                try{
                    if(this.measure == "heartrate"){
                        this.lastValue = res.data[res.data.length-1].measurement["heartrate"];
                    }else{
                        this.lastValue = res.data[res.data.length-1].calculations[this.measure].toFixed(2);
                    }
                }catch(err){

                }

            });
        }




        this.tabsub = this.ea.subscribe("change-personcard-tab", (e)=>{
            this.tab = e.tab;
        });

            this.datasub = this.ea.subscribe("test-data-"+this.person.id, (e)=>{
                if(this.state.selectedTest.status !== "active"){
                    return;
                }
                this.count++;
                if(this.measure == "heartrate"){
                    this.chart.config.data.datasets[0].data.push(parseFloat((parseFloat(e.measurement[this.measure])).toFixed(2)));
                }else{
                    try{
                        this.chart.config.data.datasets[0].data.push(parseFloat((parseFloat(e.calculations[this.measure])).toFixed(2)));
                    }catch(err){
                        console.log(e.calculations[this.measure])
                    }
                }
                this.chart.config.data.labels.push(this.dt.timeFromSeconds(e.timestamp?e.timestamp:e.createdAt));
                if(this.chart.config.data.datasets[0].data.length > this.limit){
                    this.chart.config.data.datasets[0].data.splice(0, 1);
                    this.chart.config.data.labels.splice(0, 1);
                }
                if(this.index == 0){
                    this.ea.publish("set-test-time", e.timestamp?e.timestamp:e.createdAt);
                }
                if(this.measure == "heartrate"){
                    this.lastValue = e.measurement["heartrate"];
                }else{
                    this.lastValue = parseFloat(e.calculations[this.measure]).toFixed(1);
                }
                this.reverseArr = this.reverse();
                this.chart.update("none");
            });

        this.changestatesub = this.ea.subscribe("test-status-change", (e)=>{

        });

        this.changemeasuresub = this.ea.subscribe("change-personcard-measure", (e)=>{
            this.chart.config.data.datasets[0].data = [];
            this.chart.config.data.labels = [];
            this.count = 1;
            this.measure = e.measure;
            this.unit = this.getUnit();
            this.chart.config.options.scales.y.suggestedMin = this.config.minmaxs[this.measure].min;
            this.chart.config.options.scales.y.suggestedMax = this.config.minmaxs[this.measure].max;
            if(!this.state.replay.on){
                for(let i = 0; i < this.state.testdata[this.person.id].length; i++){
                    if(this.measure == "heartrate"){
                        this.chart.config.data.datasets[0].data.push(parseFloat(parseFloat(this.state.testdata[this.person.id][i].measurement[this.measure]).toFixed(2)));
                    }else{
                        this.chart.config.data.datasets[0].data.push(parseFloat(parseFloat(this.state.testdata[this.person.id][i].calculations[this.measure]).toFixed(2)));
                    }
                    this.chart.config.data.labels.push(this.dt.timeFromSeconds(this.state.testdata[this.person.id][i].timestamp?this.state.testdata[this.person.id][i].timestamp:this.state.testdata[this.person.id][i].createdAt));
                    this.count++;
                }
                try{
                    if(this.measure == "heartrate"){
                        this.lastValue = this.state.testdata[this.person.id][this.state.testdata[this.person.id].length].measurement[this.measure];
                    }else{
                        this.lastValue = this.state.testdata[this.person.id][this.state.testdata[this.person.id].length].calculations[this.measure].toFixed(1)
                    }
                }catch(err){
    
                }
     
                this.reverseArr = this.reverse();
                this.chart.update();
                this.unit = this.getUnit();
            }

        });

        this.replaysub = this.ea.subscribe("personcard-replay", (e)=>{
            if(e.run){
                this.replaying = true;
                this.startReplay();

            }else{
                this.replaying = false;
                this.stopReplay();
            }
        });

        this.getmeasurementssub = this.ea.subscribe("personcard-get-measurements", (e)=>{

            if(!e.reverse){
                if(this.chart.config.data.datasets[0].data.length < this.limit){
                    console.log(this.limit+ " > "+this.chart.config.data.datasets[0].data.length);
                    return;
                }
                if(this.chart.config.data.datasets[0].data.length < this.limit){
                    
                }else{
                    this.skip += this.limit;
                }
            }else{
                this.skip -= this.limit;
                if(this.skip <= 0){
                    this.skip = 0;
                }
            }

            console.log(this.skip+" << skipping");



            this.count = 0;
            this.chart.config.data.datasets[0].data = [];
            this.chart.config.data.labels = [];
            this.actions.getMoreMeasurements(this.person.id, this.limit, this.skip).then((res)=>{
                console.log(res);
                for(let i = 0; i < res.data.length; i++){
                    this.count++;
                    if(this.measure == "heartrate"){
                        this.chart.config.data.datasets[0].data.push(parseFloat(parseFloat(res.data[i].measurement[this.measure]).toFixed(2)));
                    }else{
                        this.chart.config.data.datasets[0].data.push(parseFloat(parseFloat(res.data[i].calculations[this.measure]).toFixed(2)));
                    }
                    this.chart.config.data.labels.push(this.dt.timeFromSeconds(res.data.timestamp?res.data.timestamp:res.data[i].createdAt));
                    this.reverseArr = this.reverse();
                    this.chart.update();
                }
                if(this.measure == "heartrate"){
                    this.lastValue = res.data[res.data.length-1].measurement["heartrate"];
                }else{
                    this.lastValue = res.data[res.data.length-1].calculations[this.measure].toFixed(1);
                }
            });
        });
    }
    stopReplay(){
        if(this.replayInterval){
            clearInterval(this.replayInterval);
        }
        if(this.measurementInterval){
            clearInterval(this.measurementInterval);
        }
        this.replaying = false;
    }
    startReplay(){
        this.chart.config.data.datasets[0].data = [];
        this.chart.config.data.datasets[0].pointRadius = 0;
        this.chart.config.options.animation = {
            duration: 0
        }
        this.chart.config.data.labels = [];
        this.arr = [];
        this.count = 0;
        this.skip = 0;
        this.actions.getMoreMeasurements(this.person.id, this.limit, this.skip).then((res)=>{
            this.skip = this.limit;
            this.arr = res.data;
        });
        //Piirretään mittaukset
        this.replayInterval = setInterval(()=>{
            if(this.arr.length <= this.count){
                return;
            }
            if(this.measure == "heartrate"){
                this.chart.config.data.datasets[0].data.push(parseFloat((parseFloat(this.arr[this.count].measurement[this.measure])).toFixed(2)));
            }else{
                this.chart.config.data.datasets[0].data.push(parseFloat((parseFloat(this.arr[this.count].calculations[this.measure])).toFixed(2)));
            }
            this.chart.config.data.labels.push(this.dt.timeFromSeconds(this.arr[this.count].timestamp?this.arr[this.count].timestamp:this.arr[this.count].createdAt));
            if(this.index == 0){
                this.ea.publish("set-test-time", this.arr[this.count].timestamp?this.arr[this.count].timestamp:this.arr[this.count].createdAt);
            }
            if(this.measure == "heartrate"){
                this.lastValue = this.arr[this.count].measurement["heartrate"];
            }else{
                this.lastValue = parseFloat(this.arr[this.count].calculations[this.measure]).toFixed(1);
            }
            this.reverseArr = this.reverse();
            this.chart.update();
            this.count++;
        }, 1000/this.state.replay.speed);
        //Haetaan mittaukset
        this.measurementInterval = setInterval(()=>{
            if(this.arr.length < this.limit){
                this.stopReplay();
            }else{
                this.actions.getMoreMeasurements(this.person.id, this.limit, this.skip).then((res)=>{
                    this.arr = res.data;
                    this.count = 0;
                    this.skip += this.limit;
                });
            }

        }, this.limit*1000/this.state.replay.speed);

    }
    getUnit(){
        switch(this.measure){
            case "coreTemperature":
                return "°C";
            case "heartrate":
                return "bpm";
            case "heatStrainIndex":
                return "";
            case "sweating_litrePerHour":
                 return "l/h"
            case "totalSweating":
                return "g";
            default:
                return "";

        }
    }
    detached(){
        this.tabsub.dipose();
        this.changestatesub.dispose();
        this.datasub.dispose();
        this.changemeasuresub.dispose();
        this.replaysub.dispose();
        this.getmeasurementssub.dispose();
        if(this.replayInterval){
            clearInterval(this.replayInterval);
        }
        if(this.measurementInterval){
            clearInterval(this.measurementInterval);
        }
    }
    reverse() {
        return this.chart.config.data.datasets[0].data.slice().reverse();
    }
    detached(){
        clearInterval(this.interval);
    }
}