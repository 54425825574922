import {ApiService} from '../../services/api-service';
import {inject} from 'aurelia-framework';

@inject(ApiService)
export class Info{
    constructor(api){
        this.api = api;
    }
    restartApp(){
        this.api.restartApp();
    }
}