import {PLATFORM, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {UserActions} from '../../actions/user-actions';
import AppState from '../../app-state';
import Config from '../../../config';
import {DateTool} from '../../tools/date-tool';

@inject(EventAggregator, UserActions, AppState, Config, DateTool)
export class PersonsList{
    constructor(ea, actions, state, config, dt){
        this.ea = ea;
        this.actions = actions;
        this.state = state;
        this.config = config;
        this.datetool = dt;
        this.persons = [

        ];
        this.deleteIndex = -1;
        this.actions.getPersons();
    }
    newPerson(){
        this.ea.publish("persons-nav", {route: "new-person"});
    }
    openPerson(index){
        this.state.selectedPerson = this.state.persons[index];
        this.ea.publish("persons-nav", {route: "person"});
    }
    deletePerson(yes){
        if(yes){
            this.actions.deletePerson(this.deleteIndex).then(()=>{
                this.deleteIndex = -1;
            });
        }else{
            this.deleteIndex = -1;
        }
    }
}