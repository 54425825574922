import {UserActions} from '../../actions/user-actions';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import AppState from '../../app-state';
import Config from '../../../config';

@inject(UserActions, EventAggregator, AppState, Config)
export class PersonInfo{
    constructor(actions, ea, state, config){
        this.actions = actions;
        this.ea = ea;
        this.state = state;
        this.config = config;
    }
    attached(){
        if(this.state.selectedPerson.gender == 'male'){
        }
    }
    savePerson(e){
        e.preventDefault();
        e.stopPropagation();
        let obj = {
            _key: this.state.selectedPerson._key,
            name: this.pname.value,
            id: this.pid.value,
            height: eval(this.pheight.value),
            weight: eval(this.pweight.value),
            age: (new Date()).getFullYear() - parseInt(this.pbirthyear.value),
            yearOfBirth: eval(this.pbirthyear.value),
            fatPercentage: eval(this.pfatpercent.value),
            restingHeartRate: eval(this.prestinghr.value),
            maximumHeartRate: eval(this.pmaxhr.value),
            maxOxygenUptake: eval(this.poxygen.value),
            coreTemperatureUpperLimit: eval(this.ctul.value),
            coreTemperatureLowerLimit: eval(this.ctll.value),
            skinTemperatureUpperLimit: eval(this.stul.value),
            skinTemperatureLowerLimit: eval(this.stll.value),
            neutralCoreTemperature: eval(this.ncoretemperature.value),
            wholeBodySweatingrateMax: eval(this.maxwbsr.value),
            wholeBodySweatingrateMin: eval(this.minwbsr.value),
            sweatingSlope: eval(this.sweatingslope.value),
            deltaSweatingOnsetTemperature: eval(this.dsot.value),
            /*bloodVesselAge: eval(this.bloodvesselage.value),*/
            maxSkinBloodFlow: eval(this.maxskinbf.value),
            basalSkinBloodFlow: eval(this.baskinbf.value),
            minSkinBloodFlow: eval(this.minskinbf.value),
            skinMaxCoefficient: eval(this.cfskinmax.value),
            skinMinCoefficient: eval(this.cfskinmin.value)
        };
        console.log("ok1")
        if((this.ismale1 && this.ismale1.checked == true) || (this.ismale2 && this.ismale2.checked == true)){
            obj.gender ='male';
        }else{
            obj.gender = 'female';
        }
        this.actions.editPerson(obj).then((res)=>{
            this.ea.publish("persons-nav", {route:'persons-list'})
        });
    }
    closePerson(){
        this.ea.publish("persons-nav", {route:'persons-list'});
    }
}