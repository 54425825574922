import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import AppState from '../../app-state';
import {TestActions} from '../../actions/test-actions';
import {DateTool} from '../../tools/date-tool';
import Config from '../../../config';

@inject(EventAggregator, AppState, TestActions, DateTool, Config)
export class TestCaseList{
    constructor(ea, state, actions, dateTool, config){
        this.ea = ea;
        this.actions = actions;
        this.state = state;
        this.config = config;
        this.datetool = dateTool;
        this.now = new Date();
        this.deleteIndex = -1;
        this.showIndex = -1;
        this.showInfo = false;
    }
    newTestCase(){
        this.ea.publish("test-case-nav", {route:"new-test-case"});
    }
    openTest(test){
        this.actions.openTest(test);
        this.ea.publish("test-case-nav", {route:"running-test"});
    }
    deleteTest(yes){
        if(yes){
            this.actions.removeTest(this.deleteIndex).then((res)=>{
                this.deleteIndex = -1;
            });
        }else{
            this.deleteIndex = -1;
        }
    }
    show(index){
        this.showIndex = index;
        this.showInfo = true;
    }
}