import {PLATFORM, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import Settings from './resources/settings';

@inject(EventAggregator, Settings)
export class App {
 constructor(ea, settings){
   this.ea = ea;
   this.settings = settings;
 }
 attached(){
  if(!this.settings.options.code){
    setTimeout(()=>{
      this.router.navigate('/login');
    }, 500);
  }
  this.navsub = this.ea.subscribe("app-nav", (opts)=>{
    console.log(opts)
    this.router.navigateToRoute(opts.route);
  });
  this.logoutsub = this.ea.subscribe("logout", ()=>{
    this.settings.logout();
    this.router.navigateToRoute('login');
  });
 }
 detached(){
  if(this.navsub){
    this.navsub.dispose();
  }
 }
 configureRouter(config, router){
  this.router = router;
  //config.options.pushState = true;
  config.title = "HTM Supervisor";
  config.map([
    {route: ['/', '/login'], name: 'login', moduleId: PLATFORM.moduleName('./resources/login')},
    {route:['/home'], name:"home", moduleId: PLATFORM.moduleName('./resources/auth/home')}
  ])
 }
}
