import {PLATFORM, inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {TestActions} from '../actions/test-actions';
import Settings from '../../settings';

@inject(EventAggregator, TestActions, Settings)
export class TestCases{
    constructor(ea, ta, settings){
        this.ea = ea;
        this.actions = ta;
        this.settings = settings;
    }
    attached(){
        if(!this.settings.options.code){
            this.ea.publish("logout");
        }
       this.navsub = this.ea.subscribe("test-case-nav", (opts)=>{
        console.log(opts);
        console.log(this.router.routes[2]);
            console.log(opts.route+" "+this.router.routes[2].name);
            console.log(this.router.routes[2].name == opts.route);
            this.router.navigateToRoute(opts.route);
        });
        this.actions.getTests().then((res)=>{
            console.log(res);
        });
    }
    detached(){
        this.navsub.dispose();
    }
    configureRouter(config, router){
        this.router = router;
        config.map([
            {route:'', redirect:'test-case-list'},
            {route:['/test-case-list'], name:'test-case-list', moduleId:PLATFORM.moduleName('./test-case-pages/test-case-list')},
            {route:'/new-test-case', name:'new-test-case', moduleId:PLATFORM.moduleName('./test-case-pages/new-test-case')},
            {route:"/running-test", name:"running-test", moduleId:PLATFORM.moduleName('./test-case-pages/running-test')}
        ]);
    }
}