import {ApiService} from '../../services/api-service';
import {inject} from 'aurelia-framework';
import AppState from '../app-state';
import {EventAggregator} from 'aurelia-event-aggregator';

@inject(ApiService, AppState, EventAggregator)
export class TestActions{
    constructor(api, state, ea){
        this.api = api;
        this.state = state;
        this.ea = ea;
        this.ea.subscribe("test-data", (data)=>{
            console.log(data);
        });
    }
    getTests(){
        return this.api.getTests().then((res)=>{
            this.state.tests = res.tests;
            return res;
        });
    }
    addTest(test){
        return this.api.addTest(test).then((res)=>{
            this.state.tests.push(res.test);
            return res.test;
        });
    }
    editTest(){

    }
    removeTest(index){
        let test = this.state.tests[index];
        return this.api.removeTest(test).then((res)=>{
            this.state.tests.splice(index, 1);
            return res;
        });
    }
    hasPerson(test, person){
        for(let i = 0; i < test.persons.length; i++){
            if(test.persons[i] == person){
                return i;
            }
        }
        return -1;
    }
    openTest(test){
        this.state.selectedTest = test;
        localStorage.setItem("htm-selected-test", JSON.stringify(this.state.selectedTest));
    }
    activateTest(){
        return this.api.activateTest(this.state.selectedTest).then((res)=>{
            if(res.success == true){
                this.state.selectedTest.status = "active";
                localStorage.setItem("htm-selected-test", JSON.stringify(this.state.selectedTest));
            }
        });
    }
    deactivateTest(){
        return this.api.deactivateTest(this.state.selectedTest).then((res)=>{
            if(res.success == true){
                this.state.selectedTest.status = "in-active";
                this.state.selectedTest.finishedAt = Math.floor(Date.now()/1000);
                localStorage.setItem("htm-selected-test", JSON.stringify(this.state.selectedTest));
            }
        });
    }
    getTestPersons(test){
        return this.api.getTestPersons(this.state.selectedTest).then((res)=>{
            if(res.success == true){
                this.state.testPersons = res.persons;
            }
        });
    }
    getMeasurements(personid){
        return this.api.getMeasurements(this.state.selectedTest._key, personid).then((res)=>{
            this.state.testdata[personid] = res.data;
            return res;
        });
    }
    getMoreMeasurements(personid, limit, skip){
        return this.api.getMoreMeasurements(this.state.selectedTest._key, personid, limit, skip).then((res)=>{
            this.state.testdata[personid] = res.data;
            return res;
        });
    }
    setVariableParameters(data){
        return this.api.setVariableParameters(data).then((res)=>{
            if(res.success == true){
                if(Array.isArray(this.state.selectedTest.variableParameters)){
                    this.state.selectedTest.variableParameters.push(data);
                    localStorage.setItem("htm-selected-test", JSON.stringify(this.state.selectedTest));
                }
            }
            return res;
        });
    }
    getDynamicParameters(testid, personid){
        return this.api.getDynamicParameters(testid, personid).then(()=>{
            return;
        });
    }

    getCalculations(testid, personid){
        return this.api.getCalculations(testid, personid).then(()=>{
            return;
        });
    }
}