export class DateTool{
    constructor(){

    }
    dateFromSeconds(seconds){
        let dt = new Date(seconds * 1000);
        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();
        if(day < 10){
            day = '0' + day;
        }
        if(month < 10){
            month = '0' + month;
        }
        return day+"."+month+"."+year;
    }
    timeFromSeconds(seconds){
        let dt = new Date(seconds * 1000);
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let second = dt.getSeconds();
        if(hour < 10){
            hour = '0' + hour;
        }
        if(minute < 10){
            minute = '0' + minute;
        }
        if(second < 10){
            second = '0' + second;
        }
        return hour + ":" + minute + ":" + second;
    }
}